<template>
  <div
    :class="[
      'panel panel--info',
      { 'panel panel--info panel--active': isVersesPanelActive },
    ]"
  >
    <button class="button button--close" @click="closeVersesPanel">
      <i class="icon icon--close"></i>
    </button>
    <div class="panel__wrapper">
      <div class="panel__header">
        <h2 class="panel__title">
          {{ text.book }}:
          <span class="highlighted">{{ book[lang] }}</span>
        </h2>
        <h2 class="panel__title">
          {{ text.chapter }}:
          <span class="highlighted">{{ chapter }}</span>
        </h2>
      </div>
      <ul
        v-if="lang === 'en'"
        :class="[
          'panel__switcher',
          'switcher',
          { 'switcher--right': language === 'jps' },
          { 'switcher--left': language === 'esv' },
        ]"
      >
        <li>
          <button
            type="button"
            @click="language = 'esv'"
            :class="[
              'switcher__button',
              { 'switcher__button--active': language === 'esv' },
            ]"
            data-index="left"
          >
            ESV
          </button>
        </li>
        <li>
          <button
            type="button"
            @click="language = 'jps'"
            :class="[
              'switcher__button',
              { 'switcher__button--active': language === 'jps' },
            ]"
            data-index="right"
          >
            JPS
          </button>
        </li>
      </ul>
      <div class="panel__content" ref="versesContent">
        <div class="panel__table">
          <div class="panel__table-row">
            <div class="panel__table-column"></div>
            <div class="panel__table-column"></div>
          </div>

          <div
            v-for="verse in limitedVerses"
            :key="verse.id"
            :ref="verse.id"
            class="panel__table-row"
          >
            <group-heading
              v-if="verse.startGroupTitle || verse.startGroupDescription"
              :verse="verse"
            />

            <div
              v-if="
                verse.book !== 'Haftarah' ||
                (verse.book === 'Haftarah' && verse.liturgical)
              "
              class="panel__table-column panel__table-column--left"
            >
              <h3
                v-if="verse.book !== 'Blessing' && !verse.liturgical"
                class="panel__table-title"
              >
                {{ getVerse(verse, false) }}
              </h3>
              <p
                class="panel__table-paragraph panel__table-paragraph--heb-style"
              >
                {{ verse.hebrew }}
              </p>
            </div>

            <div
              v-if="
                verse.book !== 'Haftarah' ||
                (verse.book === 'Haftarah' && verse.liturgical)
              "
              class="panel__table-column panel__table-column--right"
            >
              <h3
                v-if="verse.book !== 'Blessing' && !verse.liturgical"
                class="panel__table-title"
              >
                {{ getVerse(verse) }}
                <div class="panel__info-btn" v-if="verse[language + 'Offset']">
                  <span>i</span>
                  <div class="panel__info-overlay panel__info-overlay--active">
                    Jewish and Christian versification vary at places in the
                    Bible. When this occurs, the side-by-side translations shown
                    in the panels are accurate.
                  </div>
                </div>
              </h3>
              <p class="panel__table-paragraph">{{ verse[language] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle, forEach } from "lodash";
import { mapActions, mapState, mapMutations } from "vuex";
import GroupHeading from "./parts/GroupHeading";
import translations from "../../public/data/additional_translations/section-menu.json";

export default {
  name: "VersesPanel",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      scrollingIntoView: false,
      language: savedLang === "en" ? "esv" : savedLang,
      text: [],
    };
  },
  components: {
    GroupHeading,
  },
  computed: {
    ...mapState("map", [
      "verses",
      "salonika-torah-groups",
      "salonika-haftarah-groups",
      "selectedVerse",
      "scrollTo",
    ]),
    ...mapState("verses_panel", ["isVersesPanelActive"]),
    ...mapState("menu", ["isCurrentLanguage"]),
    verseId() {
      return this.selectedVerse && this.selectedVerse.id;
    },
    book() {
      return this.selectedVerse && this.selectedVerse.book;
    },
    chapter() {
      return this.selectedVerse && this.selectedVerse.chapter;
    },
    versesKey() {
      if (!this.selectedVerse) return null;
      let key = Object.keys(this.verses).find(
        (key) => this.verses[key].id === this.selectedVerse.id
      );
      return parseInt(key);
    },
    limitedVerses() {
      let startVerse = this.versesKey - 100;
      let endVerse = this.versesKey + 100;

      if (startVerse < 0) {
        startVerse = 0;
      }

      if (endVerse > this.verses.length) {
        endVerse = this.verses.length;
      }

      let verses = this.verses.slice(startVerse, endVerse);

      verses = verses.map((verse) => {
        let thisVerse = {
          ...verse,
        };

        let matchedStartGroup = this[
          `${this.$route.params.scrollId}-groups`
        ].find((group) => group.startVerse === verse.id);

        if (matchedStartGroup) {
          thisVerse.startGroupTitle = matchedStartGroup.title[this.lang];
          thisVerse.startGroupDescription =
            matchedStartGroup.description[this.lang];
        }

        let matchedEndGroup = this[
          `${this.$route.params.scrollId}-groups`
        ].find((group) => group.endVerse === verse.id);

        if (matchedEndGroup) {
          thisVerse.endGroupTitle = matchedEndGroup.title[this.lang];
        }
        return thisVerse;
      });

      return verses;
    },
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    limitedVerses: function () {
      if (!this.scrollTo || this.scrollingIntoView) return false;
      this.scrollingIntoView = true;

      setTimeout(() => {
        if (this.$refs[this.verseId]) {
          this.$refs[this.verseId].scrollIntoView();
        }
      }, 500);

      setTimeout(() => {
        this.scrollingIntoView = false;
        this.setScrollTo(false);
      }, 2000);
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.language = value !== "en" ? value : "esv";
      this.text = translations[value][0];
    },
  },
  methods: {
    ...mapMutations("map", ["setSelectedVerse", "setScrollTo"]),
    ...mapActions("verses_panel", ["closeVersesPanel", "populateRefs"]),
    formatVerseFromVerseId(verseId) {
      const capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
      };

      let verseLabelParts = verseId.split("-");
      let book = capitalize(verseLabelParts[0]);
      let chapter = capitalize(verseLabelParts[1]);
      let verse = capitalize(verseLabelParts[2]);

      return `${book} ${chapter}:${verse}`;
    },
    getVerse(thisVerse, displayOffset = true) {
      if (thisVerse.blessing !== undefined) {
        return thisVerse.blessing;
      } else if (displayOffset && thisVerse[this.language + "Offset"]) {
        return this.formatVerseFromVerseId(thisVerse[this.language + "Offset"]);
      } else {
        return `${thisVerse.book[this.lang]} ${thisVerse.chapter}:${
          thisVerse.verse
        }`;
      }
    },
    handleScroll() {
      if (this.scrollTo) return false;

      let verses = this.verses.filter((verse) => {
        return this.$refs[verse.id];
      });

      forEach(verses, (verse) => {
        if (
          this.$refs[verse.id].getBoundingClientRect().top +
            this.$refs[verse.id].offsetHeight >
          250
        ) {
          this.setSelectedVerse(verse);

          let polygon = window.polygons.find(
            (polygon) => polygon.verse_id === verse.id
          );

          if (!polygon) return false;

          /*eslint-disable */

          let bounds = new google.maps.LatLngBounds();
          polygon.getPath().forEach((path) => {
            let point = new google.maps.LatLng(path.lat(), path.lng());
            bounds.extend(point);
          });

          /*eslint-enable */

          window.map.panTo(bounds.getCenter());

          return false;
        }
      });
    },
    handleThrottledScroll() {},
  },
  beforeUnmount() {
    this.$refs.versesContent.removeEventListener(
      "scroll",
      this.handleThrottledScroll
    );
  },
  mounted() {
    // this.populateRefs(this.$refs);
    this.handleThrottledScroll = throttle(this.handleScroll, 100);
    this.$refs.versesContent.addEventListener(
      "scroll",
      this.handleThrottledScroll
    );
  },
};
</script>
