import router from '@/router'
import SnazzyInfoWindow from "snazzy-info-window";
import chapterIndicatorIcon from "../../../src/assets/chapter-indicator-icon.svg";
import groupIndicatorIcon from "../../../src/assets/group-indicator-icon.svg";

const polyOptions = {
  strokeColor: '#fff',
  strokeOpacity: 1.0,
  strokeWeight: 1,
};

// initial state
const state = () => ({
  scrollTo: false,
  polygonVerses: [],
  verses: [],
  selectedVerse: null,
  'salonika-torah-groups': [],
  'salonika-haftarah-groups': [],
  currentCenterLng: 0,
  clickLocation: {},
})

// getters
const getters = {
  versesByLng: (state) => {

    let currentBook = null;
    let firstVerseInChapters = state.verses.filter(verse => {
      let isNewBook = currentBook === verse.book;
      currentBook = verse.book;
      return isNewBook;
    });

    let enrichedFirstVerseInChapters = firstVerseInChapters.map(verse => {
      let matchedPolygonVerse = window.polygonVerses.find(polygonVerse => polygonVerse.id === verse.id);
      if (!matchedPolygonVerse) return false;

      let firstBound = matchedPolygonVerse.boundsList && matchedPolygonVerse.boundsList.length > 0 && matchedPolygonVerse.boundsList[0];

      if (!firstBound) return false;

      return {
        book: matchedPolygonVerse.book,
        lng: firstBound.getCenter().lng()
      };
    });

    // remove any corrupted verses
    enrichedFirstVerseInChapters = enrichedFirstVerseInChapters.filter(firstVerseInChapter => firstVerseInChapter !== false);

    enrichedFirstVerseInChapters.sort((verseA, verseB) => {
      return verseA.lng - verseB.lng;
    });

    return enrichedFirstVerseInChapters;
  },
  groups: (state) => {
    return state[router.currentRoute.value.params.scrollId + '-groups'];
  },
  structuredGroups: (state, getters, rootState) => {

    let enrichedGroups = [];

    let thisGroupKey = router.currentRoute.value.params.scrollId + '-groups';
    for (let i = 0; i < state[thisGroupKey].length; i++) {

      let startVerse = state.verses.find(verse => verse.id === state[thisGroupKey][i].startVerse);
      let endVerse = state.verses.find(verse => verse.id === state[thisGroupKey][i].endVerse);

      if (!startVerse || !endVerse) continue;

      enrichedGroups.push({
        ...state[thisGroupKey][i],
        startVerseData: startVerse,
        endVerseData: endVerse
      });

    }

    // remove any corrupted groups
    enrichedGroups = enrichedGroups.filter(firstVerseInChapter => firstVerseInChapter !== false);
    let structuredGroups = [];

    for (let j = 0; j < enrichedGroups.length; j++) {
      let foundBook = structuredGroups.find(book => book.title === enrichedGroups[j].startVerseData.book[rootState.menu.isCurrentLanguage]);
      if (!foundBook) {
        foundBook = {
          title: enrichedGroups[j].startVerseData.book[rootState.menu.isCurrentLanguage],
          groups: []
        };
        structuredGroups.push(foundBook);
      }

      foundBook.groups.push(enrichedGroups[j]);
    }

    return structuredGroups;
  }
}

// actions
const actions = {

  initializeMap({ commit, rootState }) {

    // Set CSS for the control wrapper
    var controlWrapper = document.createElement('div');
    controlWrapper.classList.add('map-controls');

    // Set CSS for the zoomIn
    var zoomInButton = document.createElement('div');
    zoomInButton.classList.add('map-controls__button');
    zoomInButton.classList.add('map-controls__button--zoom-in');
    controlWrapper.appendChild(zoomInButton);

    // Set CSS for the zoomOut
    var zoomOutButton = document.createElement('div');
    zoomOutButton.classList.add('map-controls__button');
    zoomOutButton.classList.add('map-controls__button--zoom-out');
    controlWrapper.appendChild(zoomOutButton);

    /*eslint-disable */

    const mapMinZoom = 4;
    const mapMaxZoom = 11;

    const mapBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(rootState.scroll.scrollLeftLat, rootState.scroll.scrollLeftLng, true),
      new google.maps.LatLng(rootState.scroll.scrollRightLat, rootState.scroll.scrollRightLng, true),
    );

    const center = new google.maps.LatLng(84.94897457216611, rootState.scroll.startLng, true);

    let map = new google.maps.Map(
      document.getElementById('mapCanvas'),
      {
        streetViewControl: false,
        tilt: 0,
        zoom: 5,
        backgroundColor: '#000000',
        center: center,
        minZoom: mapMinZoom,
        maxZoom: mapMaxZoom,
        disableDefaultUI: true,
        restriction: {
          latLngBounds: mapBounds,
          strictBounds: true,
        },
        zoomControl: false,
        styles: [
          {
            "featureType": "all",
            "elementType": "all",
            "stylers": [
              {
                "color": "#000000"
              }
            ]
          }
        ]
      }
    );

    // Record click event location for Quiz Me
    map.addListener("click", event => {
      commit('setClickLocation', event.latLng.toJSON())
    });


    commit('setCurrentCenterLng', map.getCenter().lng());

    var maptiler = new klokantech.MapTilerMapType(map, (x, y, z) => {
      return `https://gal-map-tiles.azureedge.net/gal-blob/${router.currentRoute.value.params.scrollId}/${z}/${x}/${y}.webp`;
    }, mapBounds, mapMinZoom, mapMaxZoom);

    map.mapTypes.set('maptiler', maptiler);

    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(controlWrapper);
    // Setup the click event listener - zoomIn
    google.maps.event.addDomListener(zoomInButton, 'click', function () {
      var currentZoomLevel = window.map.getZoom();
      if (currentZoomLevel !== 0) {
        window.map.setZoom(window.map.getZoom() + 1);
      }
    });

    // Setup the click event listener - zoomOut
    google.maps.event.addDomListener(zoomOutButton, 'click', function () {
      var currentZoomLevel = window.map.getZoom();
      if (currentZoomLevel !== 0) {
        window.map.setZoom(map.getZoom() - 1);
      }
    });

    google.maps.event.addListener(map, 'zoom_changed', function () {

      if (!window.polygonVerses || window.polygonVerses.length === 0) return false;

      const zoom = window.map.getZoom();

      if (rootState.scroll.markerSizes[zoom]) {
        for (let i = 0; i < window.polygonVerses.length; i++) {
          if (window.polygonVerses[i].markers.length === 0) continue;

          for (let j = 0; j < window.polygonVerses[i].markers.length; j++) {
            window.polygonVerses[i].markers[j].setIcon({
              url: window.polygonVerses[i].markers[j].icon.url,
              scaledSize: new google.maps.Size(rootState.scroll.markerSizes[zoom].width, rootState.scroll.markerSizes[zoom].height)
            });
          }
        }
      }
      //set the icon with the new size to the marker

    });

    /*eslint-enable */

    window.map = map;

    return true;
  },
  initializeHotspots({ rootState, state, dispatch }) {

    window.polygons = [];
    window.polygonVerses = [];

    for (let i = 0; i < state.verses.length; i++) {
      if (!state.verses[i].hotspot) continue;

      let polygons = [];

      for (let j = 0; j < state.verses[i].hotspot.length; j++) {

        /*eslint-disable */

        const coords = state.verses[i].hotspot[j].map(coord => {
          return new google.maps.LatLng(coord.lat, coord.lng, true);
        });

        let polygon = new google.maps.Polygon({
          ...polyOptions,
          map: window.map,
          path: coords,
          verse_id: state.verses[i].id,
          fillColor: "#000",
          fillOpacity: 0,
          strokeColor: "#FFF",
          strokeOpacity: 0,
          strokeWeight: 1,
        });

        let bounds = new google.maps.LatLngBounds();
        for (let k in coords) {
          bounds.extend(coords[k]);
        }

        google.maps.event.addListener(polygon, 'click', (event) => {
          if (this.state.quiz_me.runQuiz) {
            // if quiz mode is active, register click for quiz mode
            this.commit('map/setClickLocation', event.latLng.toJSON());
          } else {
            //else open up the verses panel
            dispatch('verses_panel/openVersesPanel', state.verses[i].id, { root: true });
            window.map.panTo(event.latLng);
          }
        });

        window.polygons.push(polygon);
        polygons.push(polygon);

        if (!window.polygonVerses[i]) {
          window.polygonVerses[i] = {
            id: state.verses[i].id,
            polygons: [],
            boundsList: [],
            markers: [],
            book: state.verses[i].book,
          };
        }

        window.polygonVerses[i].polygons.push(polygon);
        window.polygonVerses[i].boundsList.push(bounds);

        if (
          !state.verses[i].markers ||
          (state.verses[i].markers && state.verses[i].markers[j] !== false)
        ) {

          let markerLatLng;
          // offset is to help the marker fall in between the columns
          const latOffset = -.007;
          const lngOffset = .06;
          let lat;
          let lng;

          if (state.verses[i].markers && state.verses[i].markers[j]) {
            lat = state.verses[i].markers[j].lat + latOffset;
            lng = state.verses[i].markers[j].lng + lngOffset;
          } else {
            const northEastPos = bounds.getNorthEast();
            lat = northEastPos.lat() + latOffset;
            lng = northEastPos.lng() + lngOffset;
          }

          markerLatLng = new google.maps.LatLng(lat, lng);

          const marker = new google.maps.Marker({
            position: markerLatLng,
            map: window.map,
          });

          let matchedGroup = state[router.currentRoute.value.params.scrollId + '-groups'].filter(group => group.startVerse === state.verses[i].id);
          let isStartOfGroup = matchedGroup.length > 0;

          const zoom = window.map.getZoom();

          let markerSize = rootState.scroll.markerSizes["7"];

          if (rootState.scroll.markerSizes[zoom]) {
            markerSize = rootState.scroll.markerSizes[zoom];
          }

          let markerIcon = chapterIndicatorIcon;

          if (isStartOfGroup) {
            markerIcon = groupIndicatorIcon;
          }

          marker.setIcon({
            url: markerIcon,
            scaledSize: new google.maps.Size(markerSize.width, markerSize.height)
          });

          let infoWindowContent
          if (state.verses[i].book['en'] === "Haftarah" || state.verses[i].book['en'] === "Blessing") {

            if (isStartOfGroup) {
              infoWindowContent = `${matchedGroup[0].title[this.state.menu.isCurrentLanguage]}`;
            } else {
              infoWindowContent = `${state.verses[i].book[this.state.menu.isCurrentLanguage]}&nbsp;${state.verses[i].chapter}`;
            }

          } else {

            infoWindowContent = `${state.verses[i].book[this.state.menu.isCurrentLanguage]}&nbsp;${state.verses[i].chapter}:${state.verses[i].verse}`;

            if (isStartOfGroup) {
              infoWindowContent = `${matchedGroup[0].title[this.state.menu.isCurrentLanguage]} - ${infoWindowContent}`;
            }

          }

          const infowindow = new SnazzyInfoWindow({
            marker: marker,
            position: markerLatLng,
            content: `<div>${infoWindowContent}</div>`,
            backgroundColor: '#231F20',
            maxWidth: 200,
            borderRadius: '3px',
            fontColor: '#FFFFFF',
            padding: '5px 10px',
            wrapperClass: 'verse-tooltip',
            placement: lat > 84.8 ? 'bottom' : 'top',
            shadow: {
              h: '0px',
              v: '3px',
              blur: '6px',
              spread: '0px',
              opacity: 0.5,
              color: '#000'
            },
            offset: {
              top: '-25px',
              left: '-30px'
            },
            border: {
              width: '1px',
              color: '#A38549'
            }
          });

          marker.addListener('mouseover', () => {
            infowindow.open();
            if (polygons) {
              for (let k = 0; k < polygons.length; k++) {
                polygon.setOptions({ strokeOpacity: .3 });
                polygons[k].setOptions({ strokeOpacity: .3 });
              }
            }
          });

          marker.addListener('mouseout', () => {
            infowindow.close();
            if (polygons) {
              for (let k = 0; k < polygons.length; k++) {
                polygons[k].setOptions({ strokeOpacity: 0 });
              }
            }
          });

          google.maps.event.addListener(marker, 'click', (event) => {
            dispatch('verses_panel/openVersesPanel', state.verses[i].id, { root: true });
            window.map.panTo(event.latLng);
          });

          window.polygonVerses[i].markers.push(marker);

        }

        /*eslint-enable */

      }
    }

    window.polygonVerses = window.polygonVerses.filter(polygonVerse => polygonVerse !== false);


  },
  moveMap({ rootState, state, commit }, percentageScrolled) {

    let total = (rootState.scroll.scrollLeftLng * -1) + rootState.scroll.scrollRightLng;
    let lng = ((percentageScrolled / 100) * total) + rootState.scroll.scrollLeftLng;

    /*eslint-disable */

    let latLng = new google.maps.LatLng(window.map.getCenter().lat(), lng, true);

    window.map.setCenter(latLng);

    const centeredVerse = window.polygonVerses.find(polygonVerse => {
      return polygonVerse.boundsList.length > 0 && polygonVerse.boundsList[0].getCenter().lng() < lng;
    });

    let verse = state.verses.find(verse => verse.id === centeredVerse.id);

    commit('setSelectedVerse', verse);

    /*eslint-enable */
  },
}

// mutations
const mutations = {
  setGroups(state, { groupType, groups }) {
    state[groupType + "-groups"] = groups;
  },
  setVerses(state, verses) {
    state.verses = verses;
  },
  setCurrentCenterLng(state, lng) {
    state.currentCenterLng = lng;
  },
  setSelectedVerse(state, newSelectedVerse) {
    state.selectedVerse = newSelectedVerse;
  },
  setScrollTo(state, newScrollTo) {
    state.scrollTo = newScrollTo;
  },
  setClickLocation(state, clickLocation) {
    state.clickLocation = clickLocation;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
