import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./scss/app.scss";

let app = createApp(App).use(store).use(router);

app.config.errorHandler = function (err, vm, info) {
    console.log("err", err);
    console.log("vm", vm);
    console.log("info", info);
}

app.mount('#app');