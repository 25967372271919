// initial state
const state = () => ({
  showQuizMe: false,
  runQuiz: false,
  currentQuestion: 0,
  gameOver: false,
  score: 0,
});

// getters
const getters = {};

// actions
const actions = {
  closeQuizMe({ commit }) {
    commit('setShowQuizMe', false);
  },
  openQuizMe({ commit, dispatch }) {
    dispatch('quiz_me_modal/closeQuizModal', {}, {root: true});
    commit('setShowQuizMe', true);
    commit('setRunQuiz', true);
    commit('setGameOver', false);
    commit('resetGame');
  },
  showNextQuestion({commit}) {
    commit('showNextQuestion');
  },
  quitQuiz({commit}) {
    commit('setRunQuiz', false);
    commit('setShowQuizMe', false);
    commit('setGameOver', false);
  }
};

// mutations
const mutations = {
  setShowQuizMe(state, showQuizMe) {
    state.showQuizMe = showQuizMe;
  },
  setRunQuiz(state, bool) {
    state.runQuiz = bool;
  },
  setShowQuizModal(state, showQuizModal) {
    state.showQuizModal = showQuizModal;
  },
  showNextQuestion(state) {
    state.currentQuestion += 1;
  },
  setGameOver(state, gameOver) {
    state.gameOver = gameOver;
  },
  increaseScore(state) {
    state.score += 1;
  },
  resetGame(state) {
    state.score = 0;
    state.currentQuestion = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
