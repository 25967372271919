<template>
  <footer class="footer">
    <div class="mini-scroll" ref="scroll">
      <img
        class="mini-scroll__img"
        ref="scrollImg"
        :style="{
          left: `${
            100 - (isDragging ? percentage : mapPercentage) * multiplier
          }%`,
          transform: `translateX(${
            ((100 - (isDragging ? percentage : mapPercentage)) / 100) *
              scrollMaxX *
              -1 +
            ((isDragging ? percentage : mapPercentage) / 100) * 150
          }px)`,
        }"
        :src="`/img/${$route.params.scrollId}.png`"
      />
      <div
        class="mini-scroll__draggable"
        @mousedown="dragStart"
        @touchstart="dragStart"
        :style="{ left: `${isDragging ? percentage : mapPercentage}%` }"
        ref="draggable"
      >
        <div class="mini-scroll__draggable-box"></div>
        <div class="mini-scroll__draggable-book">
          {{ text.book }}: {{ currentBook }}
        </div>
      </div>
    </div>
    <div class="footer__navbar">
      <button
        type="button"
        class="footer__nav-menu-button"
        @click="setIsMenuActive(true)"
      >
        <img src="/img/menu-button.svg" alt="Menu button" class="icon" />{{
          text.section_menu
        }}
      </button>
      <section-menu v-show="isMenuActive" />
    </div>
  </footer>
</template>
<script>
import SectionMenu from "../components/SectionMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import translations from "../../public/data/additional_translations/footer.json";

export default {
  name: "ViewerFooter",
  components: {
    SectionMenu,
  },
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      scrollImgWidth: 1100,
      scrollMaxX: 1347,
      scrollMinX: 0,
      posX: 0,
      isDragging: false,
      percentage: 0,
      multiplier: 17.13,
      mapLng: 0,
      text: [],
    };
  },
  computed: {
    ...mapState("menu", ["isMenuActive", "isCurrentLanguage"]),
    ...mapState("scroll", ["scrollLeftLng", "scrollRightLng"]),
    ...mapGetters("map", ["versesByLng"]),
    currentBook() {
      let book = this.versesByLng.find((book) => book.lng > this.mapLng);
      return !book ? "" : book.book;
    },
    mapPercentage() {
      let total = -1 * this.scrollLeftLng + this.scrollRightLng;

      let percent =
        100 * (1 - (total - (-1 * this.scrollLeftLng + this.mapLng)) / total);

      if (this.scrollImgWidth > 800) {
        let range = 2.5 * (this.scrollImgWidth / 1000);
        if (percent > 50) {
          let x = 100 - percent;
          range = range * (1 - x / 50);
        } else {
          let x = 50 - percent;
          range = -1 * (range * (x / 50));
        }
        percent += range;
      }

      if (percent > 100) {
        percent = 100;
      } else if (percent < 0) {
        percent = 0;
      }

      return percent;
    },
  },

  methods: {
    ...mapMutations("menu", ["setIsMenuActive"]),
    ...mapActions("map", ["moveMap"]),
    dragStart(e) {
      e.preventDefault();
      this.isDragging = true;
      this.posX = e.clientX;
      this.scrollMaxX = this.$refs.scroll.offsetWidth;
      document.onmouseup = this.dragStop;
      document.ontouchend = this.dragStop;
      document.onmousemove = this.dragScroll;
      document.ontouchmove = this.dragScroll;
    },
    dragStop() {
      document.onmouseup = null;
      document.ontouchend = null;
      document.onmousemove = null;
      document.ontouchmove = null;
      this.isDragging = false;
    },
    dragScroll(e) {
      if (e.touches) {
        e = e.touches[0];
      }

      // calculate the new cursor position:
      let pos1 = this.posX - e.clientX;
      this.posX = e.clientX;

      // set the element's new position:
      let currentPosition = this.$refs.draggable.offsetLeft - pos1;

      if (currentPosition < this.scrollMinX) {
        currentPosition = this.scrollMinX;
      } else if (currentPosition > this.scrollMaxX) {
        currentPosition = this.scrollMaxX;
      }

      let percentage = (currentPosition / this.scrollMaxX) * 100;

      this.percentage = percentage;

      this.multiplier =
        this.$refs.scrollImg.offsetWidth / this.$refs.scroll.offsetWidth;

      this.scrollImgWidth = this.$refs.scrollImg.offsetWidth;

      this.moveMap(percentage);
    },
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    isMenuActive: function (newIsMenuActive) {
      if (newIsMenuActive) {
        document.body.classList.add("body--overlay");
      } else {
        document.body.classList.remove("body--overlay");
      }
    },
    mapPercentage: function (newMapPercentage) {
      if (!this.isDragging) {
        this.percentage = newMapPercentage;
      }
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = translations[value][0];
    },
  },
  mounted() {
    setTimeout(this.initializeData, 1000);
    document.addEventListener("mouseup", this.dragStop);

    this.mapLng = window.map.getCenter().lng();
    window.map.addListener("bounds_changed", () => {
      this.mapLng = window.map.getCenter().lng();
    });
  },
};
</script>


