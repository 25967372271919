// initial state
const state = () => ({
  showModal: false,
});

// getters
const getters = {};

// actions
const actions = {
  closeModal({ commit }) {
    document.body.classList.remove('body--overlay');
    document.body.classList.remove('body--overlay-modal');
    commit('setShowModal', false);
  },
  openModal({ commit }) {
    document.body.classList.add('body--overlay');
    document.body.classList.add('body--overlay-modal');
    commit('setShowModal', true);
  }
};

// mutations
const mutations = {
  setShowModal(state, showModal) {
    state.showModal = showModal;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
