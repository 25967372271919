<template>
  <header class="header">
    <div class="header__logo-wrap">
      <router-link to="/">
        <img
          src="@/assets/logo.svg"
          alt="God's Ancient Library"
          class="header__logo"
        />
      </router-link>
      <h1 class="header__navbar-heading">{{ title[lang] }}</h1>
    </div>
    <div class="header__navbar">
      <button
        :class="[
          'menu__toggle',
          { 'menu__toggle--active': isMobileMenuActive === true },
        ]"
        @click="setIsMobileMenuActive(!isMobileMenuActive)"
      >
        <span class="menu__toggle-icon menu__toggle-icon--1"></span>
        <span class="menu__toggle-icon menu__toggle-icon--2"></span>
        <span class="menu__toggle-icon menu__toggle-icon--3"></span>
      </button>
      <ul
        :class="[
          'menu menu--header',
          { 'menu--header--active': isMobileMenuActive === true },
        ]"
        v-show="isMobileMenuActive"
      >
        <li
          v-for="(menuItem, itemIndex) in menuItems[lang]"
          :key="itemIndex"
          :class="[
            'menu__item',
            {
              'menu__item--has-children':
                menuItem.subItems && menuItem.subItems.length > 0,
            },
            { 'menu__item--active': activeMenuItem === itemIndex },
          ]"
        >
          <a
            v-if="menuItem.title === 'Language'"
            :href="menuItem.url"
            class="menu__link menu__link--language"
            :ariaLabel="menuItem.title"
            :title="menuItem.title"
            @click="
              activeMenuItem = itemIndex === activeMenuItem ? null : itemIndex
            "
            ><i class="icon icon--global"></i
          ></a>
          <a
            v-else-if="menuItem.url"
            :href="menuItem.url"
            class="menu__link"
            :ariaLabel="menuItem.title"
            :title="menuItem.title"
            @click="
              activeMenuItem = itemIndex === activeMenuItem ? null : itemIndex
            "
            >{{ menuItem.title }}</a
          >
          <a
            v-else-if="
              menuItem.modal && menuItem.modal === 'content-credits-modal'
            "
            class="menu__link"
            :ariaLabel="menuItem.title"
            :title="menuItem.title"
            @click="openModal"
            >{{ menuItem.title }}</a
          >
          <a
            v-else-if="menuItem.modal && menuItem.modal === 'quiz-me-modal'"
            class="menu__link--quiz"
            :ariaLabel="menuItem.title"
            :title="menuItem.title"
            @click="openQuizModal"
            >{{ !quizActive ? menuItem.title : text.exit_quiz }}</a
          >
          <ul
            v-if="menuItem.subItems && menuItem.subItems.length > 0"
            class="submenu"
          >
            <li
              class="submenu__item"
              v-for="(subItem, subItemIndex) in menuItem.subItems"
              :key="subItemIndex"
            >
              <a
                v-if="subItem.url"
                :href="subItem.url"
                :ariaLabel="subItem.linkTitle"
                :title="subItem.linkTitle"
                >{{ subItem.linkTitle }}</a
              >
              <a
                v-else
                v-on:click="setLanguage"
                :ariaLabel="subItem.linkTitle"
                :title="subItem.linkTitle"
                :id="subItem.id"
                @click="
                  setIsCurrentLanguage(subItem.id) & (activeMenuItem = null)
                "
                >{{ subItem.linkTitle }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import quizText from "../../public/data/quizzes/quiz-mode.json";

export default {
  name: "ViewerHeader",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      groups: null,
      activeMenuItem: null,
      quizActive: false,
      text: [],
    };
  },
  computed: {
    ...mapState("scroll", ["title", "menuItems"]),
    ...mapState("menu", ["isMobileMenuActive", "isCurrentLanguage"]),
    ...mapState("quiz_me", ["runQuiz"]),
  },
  methods: {
    ...mapMutations("menu", ["setIsMobileMenuActive", "setIsCurrentLanguage"]),
    ...mapActions("copyright_content_modal", ["openModal"]),
    ...mapActions("quiz_me_modal", ["openQuizModal"]),
  },
  beforeMount() {
    this.text = quizText[this.lang][0];
  },
  watch: {
    isMobileMenuActive: function (newIsMobileMenuActive) {
      if (newIsMobileMenuActive) {
        document.body.classList.add("body--overlay");
      } else {
        document.body.classList.remove("body--overlay");
      }
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = quizText[value][0];
    },
    runQuiz(value) {
      this.quizActive = value;
    },
  },
};
</script>
