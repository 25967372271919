<template>
  <div class="modal" v-if="showQuizModal">
    <div class="modal__inner">
      <div v-if="!runQuiz && !gameOver" class="modal__body">
        <h1 class="header__navbar-heading">
          {{ text.welcome_modal_title }}
        </h1>
        <p>{{ text.welcome_modal_content }}</p>
        <button class="button button__large" @click="openQuizMe">
          {{ text.start }}
        </button>
        <button
          class="button button__large button__large--outline"
          @click="closeQuizModal"
        >
          {{ text.exit }}
        </button>
      </div>
      <div v-else-if="runQuiz && !gameOver" class="modal__body">
        <h1 class="header__navbar-heading">{{ text.are_you_sure }}</h1>
        <p>{{ text.confirmation }}</p>
        <button class="button button__large" @click="closeQuizModal">
          {{ text.back_to_quiz }}
        </button>
        <button
          class="button button__large button__large--outline"
          @click="exitQuiz"
        >
          {{ text.exit_quiz }}
        </button>
      </div>
      <div v-else-if="runQuiz && gameOver" class="modal__body">
        <h1 class="header__navbar-heading">{{ text.congratulations }}</h1>
        <p>
          {{ text.you_completed_the_game }} {{ this.score }} {{ text.of }} 10.
          {{ text.you_may_now }}.
        </p>
        <button class="button button__large" @click="openQuizMe">
          {{ text.try_again }}
        </button>
        <button
          class="button button__large button__large--outline"
          @click="exitQuiz"
        >
          {{ text.exit_quiz }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import quizText from "../../public/data/quizzes/quiz-mode.json";

export default {
  name: "QuizMeModal",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      text: [],
    };
  },
  computed: {
    ...mapState("menu", ["isCurrentLanguage"]),
    ...mapState("quiz_me_modal", ["showQuizModal"]),
    ...mapState("quiz_me", ["showQuizMe", "runQuiz", "score", "gameOver"]),
  },
  methods: {
    ...mapActions("quiz_me_modal", ["closeQuizModal"]),
    ...mapActions("quiz_me", ["openQuizMe", "quitQuiz"]),
    exitQuiz() {
      this.closeQuizModal();
      this.quitQuiz();
    },
  },
  beforeMount() {
    this.text = quizText[this.lang][0];
  },
  watch: {
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = quizText[value][0];
    },
  },
};
</script>
