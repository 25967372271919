<template>
  <div class="modal" v-if="showModal">
    <div class="modal__inner">
      <div class="modal__body">
        <button class="button button--close" @click="closeModal">
          <i class="icon icon--close"></i>
        </button>

        <h1 class="header__navbar-heading">{{ text.content_credits }}</h1>
        <p>
          <strong>{{ text.hebrew_text }}</strong> {{ text.hebrew_text_text }}
        </p>
        <p>
          <strong>{{ text.jps }}</strong> {{ text.jps_text }}
        </p>
        <p>
          <strong>{{ text.esv }}</strong> {{ text.esv_text_a }}
        </p>
        <p>{{ text.esv_text_b }}</p>
        <p>{{ text.esv_text_c }}</p>
        <p>{{ text.esv_text_d }}</p>
        <p>{{ text.esv_text_e }}</p>
        <p>{{ text.esv_text_f }}</p>
        <p>{{ text.esv_text_g }}</p>
        <p>{{ text.esv_text_h }}</p>
        <p>{{ text.esv_text_i }}</p>
        <p>{{ text.esv_text_j }}</p>
        <p>{{ text.esv_text_k }}</p>
        <p>{{ text.esv_text_l }}</p>
        <p>{{ text.esv_text_m }}</p>
        <p>{{ text.esv_text_n }}</p>
        <p>
          <strong>{{ text.es }}</strong> <span v-html="text.es_text"></span>
        </p>
        <p>
          <strong>{{ text.fr }}</strong> <span v-html="text.fr_text"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import translations from "../../public/data/additional_translations/credits.json";

export default {
  name: "CopyrightContentModal",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      text: [],
    };
  },
  computed: {
    ...mapState("copyright_content_modal", ["showModal"]),
    ...mapState("menu", ["isCurrentLanguage"]),
  },
  methods: {
    ...mapActions("copyright_content_modal", ["closeModal"]),
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = translations[value][0];
    },
  },
};
</script>
