import Vuex from 'vuex';
import map from './modules/map';
import menu from './modules/menu';
import scroll from './modules/scroll';
import verses_panel from './modules/verses-panel';
import copyright_content_modal from './modules/copyright-content-modal';
import quiz_me_modal from './modules/quiz-me-modal';
import quiz_me from './modules/quiz-me';

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    scroll,
    map,
    menu,
    verses_panel,
    copyright_content_modal,
    quiz_me_modal,
    quiz_me,
  },
  strict: debug,
});
