<template>
  <div v-show="showPanel" class="panel panel--polygons">
    <div class="panel__header">
      <h3 class="panel__title">JSON Coordinates</h3>
      <div class="buttons">
        <button type="button" id="mapPanelClearBtn" class="button js-clear-polygon"
                @click="clearPolygon">Clear</button>
        <button type="button" class="button js-add-polygon"
                @click="addPolygon">Add Polygon</button>
      </div>
    </div>
    <div class="panel__current">Current Polygon: {{ selectedPolyIndex }}</div>
    <div class="panel__content">{{coordinates}}</div>
  </div>
</template>

<script>
const polyOptions = {
  strokeColor: "#fff",
  strokeOpacity: 1.0,
  strokeWeight: 2
};

export default {
  name: "EditorPanel",
  components: {},
  data() {
    return {
      showPanel: false,
      markers: [],
      polygon: [],
      selectedPolyIndex: 0
    };
  },
  computed: {
    // Generate coordinates in JSON format
    coordinates() {
      if (!this.polygon) return null;

      let coordinates = [];

      for (let i = 0; i < this.polygon.length; i++) {
        let path = this.polygon[i].getPath();

        for (let j = 0; j < path.getLength(); j++) {
          let pathPoint = path.getAt(j);

          if (!coordinates[i]) {
            coordinates[i] = [];
          }

          coordinates[i].push(pathPoint.toJSON());
        }
      }

      return coordinates;
    }
  },
  methods: {
    /* Marker add function */
    addLatLng(event) {
      let latLng = event.latLng;

      // Add latLng to path
      let path = this.polygon[this.selectedPolyIndex].getPath();
      path.push(latLng);

      // Marker options
      let markerOptions = {
        position: latLng,
        draggable: true,
        title: "#" + path.getLength(),
        map: window.map,
        selected_polygon: this.selectedPolyIndex,
        icon: "/img/dot_black.svg"
      };

      /*eslint-disable */

      // Marker init
      let marker = new google.maps.Marker(markerOptions);

      /*eslint-enable */

      // add listener to redraw the polyline when markers position change
      marker.addListener("position_changed", () => {
        this.drawPolyline();
      });

      if (!window.markers) {
        window.markers = [];
      }

      if (!window.markers[this.selectedPolyIndex]) {
        window.markers[this.selectedPolyIndex] = [];
      }

      window.markers[this.selectedPolyIndex].push(marker);

      // Activate buttons
      // activateBtn();
    },
    drawPolyline() {
      let markersPositionArray = [];
      // obtain latlng of all markers on map
      window.markers[this.selectedPolyIndex].forEach(function(e) {
        markersPositionArray.push(e.getPosition());
      });

      // check if there is already polyline drawn on map
      // remove the polyline from map before we draw new one
      if (this.polygon[this.selectedPolyIndex] !== null) {
        this.polygon[this.selectedPolyIndex].setMap(null);
      }

      /*eslint-disable */

      // draw new polyline at markers' position
      let this_polygon = new google.maps.Polygon({
        ...polyOptions,
        map: window.map,
        path: markersPositionArray,
        selected_polygon: this.selectedPolyIndex
      });

      /*eslint-enable */

      this.polygon[this.selectedPolyIndex] = this_polygon;

      this.polygon[this.selectedPolyIndex].addListener("click", () => {
        this.selectedPolyIndex = this_polygon.selected_polygon;
        this.activatePolygon();
      });
    },
    addPolygon() {
      this.selectedPolyIndex = this.polygon.length;

      /*eslint-disable */

      let this_polygon = new google.maps.Polygon({
        ...polyOptions,
        map: window.map,
        selected_polygon: this.selectedPolyIndex
      });

      /*eslint-enable */

      this_polygon.addListener("click", () => {
        this.selectedPolyIndex = this_polygon.selected_polygon;
        this.activatePolygon();
      });

      this.polygon.push(this_polygon);

      if (window.markers && window.markers.length) {
        this.activatePolygon();
      }

      return true;
    },
    activatePolygon() {
      // set all polygons to "deselected" state
      for (let i = 0; i < this.polygon.length; i++) {
        this.polygon[i].setOptions({ strokeOpacity: 0.25 });
      }

      // set all polygons to "selected" state
      this.polygon[this.selectedPolyIndex].setOptions({ strokeOpacity: 1 });

      window.markers = window.markers.filter(el => {
        return el != null;
      });

      for (let i = 0; i < window.markers.length; i++) {
        for (let j = 0; j < window.markers[i].length; j++) {
          window.markers[i][j].setDraggable(
            window.markers[i][j].selected_polygon === this.selectedPolyIndex
          );
        }
      }
    },
    clearPolygon() {
      // Remove markers
      this.removeMarkers();

      // Remove polygons
      this.removePolygon();

      // @todo Set empty string to the panel content

      // @todo Hide buttons
      // deactivateBtn();

      // @todo Add comment
      this.addPolygon();
    },
    removeMarkers() {
      for (let i = 0; i < window.markers.length; i++) {
        for (let j = 0; j < window.markers[i].length; j++) {
          window.markers[i][j].setMap(null);
        }
        window.markers[i] = [];
      }
    },
    removePolygon() {
      for (let i = 0; i < this.polygon.length; i++) {
        let path = this.polygon[i].getPath();
        for (let j = 0; j < path.getLength(); j++) {
          path.removeAt(j);
        }
      }
      for (let i = 0; i < this.polygon.length; i++) {
        this.polygon[i].setMap(null);
      }
      this.selectedPolyIndex = 0;
      this.polygon.length = 0;
    }
  },
  mounted() {
    this.showPanel = true;
    this.addPolygon();
    window.map.addListener("click", this.addLatLng);
  }
};
</script>