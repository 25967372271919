import { createRouter, createWebHistory } from 'vue-router'
import Scroll from '../views/Scroll.vue'

const routes = [
  {
    path: '/',
    redirect: '/salonika-torah'
  },
  {
    path: '/:scrollId',
    name: 'Scroll',
    component: Scroll,
    meta: {
      'salonika-torah': 'Salonika Torah | God\'s Ancient Library',
      'salonika-haftarah': 'Salonika Haftarah | God\'s Ancient Library',
    }
  },
  {
    path: '/:scrollId/edit',
    name: 'Edit',
    component: Scroll,
    meta: { edit: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params && to.meta && to.meta[to.params.scrollId]) {
    document.title = to.meta[to.params.scrollId];
  }
  next();
});

export default router;
