// initial state
const state = () => ({
  isMenuActive: false,
  isMobileMenuActive: false,
  isCurrentLanguage: window.localStorage.getItem("siteLang") ? window.localStorage.getItem("siteLang") : "en"
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setIsMenuActive(state, isMenuActive) {
    state.isMenuActive = isMenuActive;
  },
  setIsMobileMenuActive(state, isMobileMenuActive) {
    state.isMobileMenuActive = isMobileMenuActive;
  },
  setIsCurrentLanguage(state, isCurrentLanguage) {
    state.isCurrentLanguage = isCurrentLanguage;
    window.localStorage.setItem('siteLang', isCurrentLanguage);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
