<template>
  <div class="panel__table-column panel__table-column--full-width">
    <h4 class="panel__group-heading" v-if="verse.startGroupTitle">
      {{ verse.startGroupTitle }}
    </h4>
    <p v-if="verse.startGroupDescription">{{ verse.startGroupDescription }}</p>
    <p v-if="referencedGroup(verse.id)">
      <a
        target="_blank"
        :href="`/${
          $route.params.scrollId === 'salonika-haftarah'
            ? 'salonika-torah'
            : 'salonika-haftarah'
        }?verse=${referencedGroup(verse.id).startVerse}`"
        >{{ text.reference }}
        {{
          $route.params.scrollId === "salonika-haftarah"
            ? text.torah
            : text.haftarah
        }}
        - {{ text.week }} {{ referencedGroup(verse.id).week }}</a
      >
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import translations from "../../../public/data/additional_translations/group-heading.json";

export default {
  name: "group-heading",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      text: [],
    };
  },
  props: ["verse"],
  computed: {
    ...mapState("menu", ["isCurrentLanguage"]),
    ...mapState("map", ["salonika-torah-groups", "salonika-haftarah-groups"]),
  },
  methods: {
    referencedGroup(versedId) {
      let matchedGroup = this[`${this.$route.params.scrollId}-groups`].find(
        (group) => group.startVerse === versedId
      );

      if (!matchedGroup) return false;

      let oppositeScrollKey =
        this.$route.params.scrollId === "salonika-haftarah"
          ? "salonika-torah"
          : "salonika-haftarah";

      let matchedCorrespondingGroup = this[`${oppositeScrollKey}-groups`].find(
        (group) => group.week === matchedGroup.week
      );

      if (!matchedCorrespondingGroup) return false;

      return matchedCorrespondingGroup;
    },
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = translations[value][0];
    },
  },
};
</script>
