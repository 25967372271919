<template>
  <div id="quizMe" class="quizMe" v-if="showQuizMe">
    <button class="quizMe__minimize-button" @click="minimizeQuizMe">
      {{ minimized ? "+" : "-" }}
    </button>
    <div class="quizMe__inner">
      <div v-if="!answerCorrect">
        <div class="quizMe__header">
          <div class="quizMe__header--question">
            <p class="quizMe__number">
              {{ text.question }} {{ this.currentQuestion + 1 }}
              {{ text.of }} 10
            </p>
            <p
              class="quizMe__question"
              v-html="questions[this.currentQuestion].question[this.lang]"
            />
          </div>
          <div class="quizMe__header--buttons">
            <button
              @click="minimizeQuizMe"
              class="button button__large button__large--light"
            >
              {{ text.answer }}
            </button>
            <button class="button button__large" @click="nextQuestion">
              {{ text.next }}
            </button>
          </div>
        </div>
        <div v-if="attemptsRemaining < 10" class="quizMe__hints-container">
          <div class="quizMe__hint">
            <button
              @click="showHintOne"
              class="button button__large button__large--light"
            >
              {{ text.hint }}1
            </button>
            <p
              v-if="hintOneVisible"
              v-html="questions[this.currentQuestion].hints[0][this.lang]"
            />
          </div>
          <div
            v-if="hintOneVisible && attemptsRemaining < 9"
            class="quizMe__hint"
          >
            <button
              @click="showHintTwo"
              class="button button__large button__large--light"
            >
              {{ text.hint }}2
            </button>
            <p
              v-if="hintTwoVisible"
              v-html="questions[this.currentQuestion].hints[1][this.lang]"
            />
          </div>
          <div
            v-if="hintTwoVisible && attemptsRemaining < 8"
            class="quizMe__hint"
          >
            <button
              @click="showHintThree"
              class="button button__large button__large--light"
            >
              {{ text.hint }}3
            </button>
            <p
              v-if="hintThreeVisible"
              v-html="questions[this.currentQuestion].hints[2][this.lang]"
            />
          </div>
        </div>
      </div>
      <div v-else-if="answerCorrect">
        <div class="quizMe__header">
          <div class="quizMe__header--question">
            <p class="quizMe__correct">{{ text.correct_answer }}</p>
            <p
              class="quizMe__question"
              v-html="questions[this.currentQuestion].onHover[this.lang]"
            />
          </div>
          <div class="quizMe__header--buttons">
            <button
              class="button button__large button__large--light"
              @click="nextQuestion"
            >
              {{ text.next }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition name="incorrect">
      <div v-if="answerWrong" class="quizMe__header quiz-Me__header--wrong">
        <div>
          <p class="quizMe__correct">{{ text.incorrect_answer }}</p>
          <p>
            {{ text.you_have }} {{ attemptsRemaining }}
            {{ text.attempts_remaining }}
          </p>
          <p v-if="attemptsRemaining === 0">{{ text.moving_on }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import quizText from "../../public/data/quizzes/quiz-mode.json";
import salonikaTorahQuiz from "../../public/data/quizzes/salonika-torah-quiz.json";
// import salonikaTorahQuiz from "../../public/data/quizzes/salonika-torah-quiz-translations.json";
import salonikaHaftarahQuiz from "../../public/data/quizzes/salonika-haftarah-quiz.json";
// import salonikaHaftarahQuiz from "../../public/data/quizzes/salonika-haftarah-quiz-translations.json";

export default {
  name: "QuizMe",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      text: [],
      questions: [],
      hintOneVisible: false,
      hintTwoVisible: false,
      hintThreeVisible: false,
      currentAnswerLocA: [],
      currentAnswerLocB: [],
      answerCorrect: false,
      answerWrong: false,
      quizActive: false,
      attemptsRemaining: 10,
      minimized: false,
    };
  },
  computed: {
    ...mapState("quiz_me", [
      "showQuizMe",
      "runQuiz",
      "currentQuestion",
      "gameOver",
    ]),
    ...mapState("menu", ["isCurrentLanguage"]),
    ...mapState("scroll", ["title"]),
    ...mapState("map", ["clickLocation"]),
  },
  methods: {
    ...mapActions("quiz_me", ["closeQuizMe", "showNextQuestion"]),
    ...mapActions("quiz_me_modal", ["openQuizModal"]),
    ...mapMutations("quiz_me", ["setGameOver", "increaseScore"]),
    nextQuestion() {
      this.hintOneVisible = false;
      this.hintTwoVisible = false;
      this.hintThreeVisible = false;
      this.answerCorrect = false;
      this.quizActive = true;
      this.currentAnswerLocA = [];
      this.currentAnswerLocB = [];
      this.showNextQuestion();
      this.setCurrentAnswerLoc();
      this.attemptsRemaining = 10;
    },
    showHintOne() {
      this.hintOneVisible = true;
    },
    showHintTwo() {
      this.hintTwoVisible = true;
    },
    showHintThree() {
      this.hintThreeVisible = true;
    },
    setCurrentAnswerLoc() {
      if (this.currentQuestion < 10) {
        let answers = this.questions[this.currentQuestion].answer;
        for (let i = 0; i < answers.length; i++) {
          let latRange = [];
          let lngRange = [];
          answers[i].map((el) => {
            latRange.push(el.lat);
            lngRange.push(el.lng);
          });
          let maxLat = Math.max.apply(null, latRange);
          let minLat = Math.min.apply(null, latRange);
          let maxLng = Math.max.apply(null, lngRange);
          let minLng = Math.min.apply(null, lngRange);
          i === 0
            ? (this.currentAnswerLocA = [
                [maxLat, minLat],
                [maxLng, minLng],
              ])
            : (this.currentAnswerLocB = [
                [maxLat, minLat],
                [maxLng, minLng],
              ]);
        }
      }
    },
    answerIsCorrect() {
      this.minimized ? this.minimizeQuizMe() : null;
      this.answerCorrect = true;
      this.quizActive = false;
      this.increaseScore();
    },
    answerIsIncorrect() {
      this.minimized ? this.minimizeQuizMe() : null;
      this.attemptsRemaining--;
      this.answerWrong = true;
      this.quizActive = false;
      setTimeout(
        () => {
          this.answerWrong = false;
          this.attemptsRemaining === 0 ? this.nextQuestion() : null;
          this.quizActive = true;
        },
        this.attemptsRemaining > 0 ? 3000 : 6000
      );
    },
    checkAnswer(value) {
      let aCorrect = false;
      let bCorrect = false;
      let xGood =
        value.lat < this.currentAnswerLocA[0][0] &&
        value.lat > this.currentAnswerLocA[0][1]
          ? true
          : false;
      let yGood =
        value.lng < this.currentAnswerLocA[1][0] &&
        value.lng > this.currentAnswerLocA[1][1]
          ? true
          : false;
      xGood && yGood ? (aCorrect = true) : null;
      if (this.currentAnswerLocB.length > 0) {
        let xGood =
          value.lat < this.currentAnswerLocB[0][0] &&
          value.lat > this.currentAnswerLocB[0][1]
            ? true
            : false;
        let yGood =
          value.lng < this.currentAnswerLocB[1][0] &&
          value.lng > this.currentAnswerLocB[1][1]
            ? true
            : false;
        xGood && yGood ? (bCorrect = true) : null;
      }
      aCorrect || bCorrect ? this.answerIsCorrect() : this.answerIsIncorrect();
    },
    minimizeQuizMe() {
      const quizContainer = document.getElementById("quizMe");
      this.minimized
        ? quizContainer.classList.remove("minimized")
        : quizContainer.classList.add("minimized");
      this.minimized = !this.minimized;
    },
  },
  beforeMount() {
    this.text = quizText[this.lang][0];
  },
  watch: {
    runQuiz(value) {
      this.quizActive = value;
      if (value === true) {
        this.questions =
          this.title["en"] === "Salonika Torah"
            ? salonikaTorahQuiz
            : salonikaHaftarahQuiz;
        this.setCurrentAnswerLoc();
      }
    },
    clickLocation(value) {
      this.quizActive ? this.checkAnswer(value) : null;
    },
    currentQuestion(value) {
      value === 10 ? this.setGameOver(true) : null;
    },
    gameOver(value) {
      value === true ? this.openQuizModal() : null;
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = quizText[value][0];
    },
  },
};
</script>

<style>
.incorrect-enter-active {
  animation: slideIn 0.3s ease-out;
}
.incorrect-leave-active {
  animation: slideIn 0.3s ease-in reverse;
}
@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>