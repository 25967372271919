<template>
  <div class="home">
    <div
      v-show="!isMapHeightInitialized"
      :class="[
        'loading',
        { 'loading--started': isMapHeightInitializedStarted },
      ]"
    >
      <div class="loading__ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <welcome-modal />
    <copyright-content-modal />
    <quiz-me-modal />
    <quiz-me />
    <viewer-header ref="header" />
    <section class="map">
      <div
        id="mapCanvas"
        :class="[
          'map__canvas',
          { 'map__canvas--panel-open': isVersesPanelActive },
        ]"
        ref="map"
      ></div>
      <editor-panel v-if="$route.meta.edit && isMapInitialized" />
      <verses-panel />
      <div class="map__legend">
        <ul>
          <li class="map__legend-item">
            <img
              src="@/assets/group-indicator-icon.svg"
              alt="Weeks Pin"
              class="marker marker--groups"
            />
            <span>= {{ text.highlighted_weeks }}</span>
          </li>
          <li class="map__legend-item">
            <img
              src="@/assets/chapter-indicator-icon.svg"
              alt="Chapter Pin"
              class="marker marker--chapter"
            />
            <span
              >=
              {{
                $route.params.scrollId
                  ? text.navigation_help
                  : text.chapter_marker
              }}</span
            >
          </li>
        </ul>
      </div>
    </section>
    <viewer-footer v-if="isMapInitialized" ref="footer" />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState, mapMutations } from "vuex";
// import WelcomeModal from "@/components/WelcomeModal";
import EditorPanel from "../components/EditorPanel";
import ViewerFooter from "../components/ViewerFooter";
import ViewerHeader from "../components/ViewerHeader";
import VersesPanel from "../components/VersesPanel";
import CopyrightContentModal from "../components/CopyrightContentModal";
import QuizMeModal from "../components/QuizMeModal";
import QuizMe from "../components/QuizMe";
import translations from "../../public/data/additional_translations/scroll.json";

export default {
  name: "Scroll",
  components: {
    // WelcomeModal,
    EditorPanel,
    ViewerFooter,
    ViewerHeader,
    VersesPanel,
    CopyrightContentModal,
    QuizMeModal,
    QuizMe,
  },
  data() {
    return {
      isMapInitialized: false,
      isMapHeightInitialized: false,
      isMapHeightInitializedStarted: false,
      lang: "en",
      text: [],
    };
  },
  computed: {
    ...mapState("verses_panel", ["isVersesPanelActive"]),
    ...mapState("menu", ["isCurrentLanguage"]),
  },
  methods: {
    ...mapActions("map", [
      "initializeMap",
      "initializeHotspots",
      "openVerseOverlay",
    ]),
    ...mapActions("verses_panel", ["openVersesPanel"]),
    ...mapMutations("map", ["setVerses", "setGroups"]),
    ...mapMutations("scroll", ["setScrollData"]),
    matchHeight() {
      if (!this.$refs.footer) return false;
      let footerHeight = this.$refs.footer.$el.clientHeight;
      let headerHeight = this.$refs.header.$el.clientHeight;
      let mapHeight = window.innerHeight - footerHeight - headerHeight;
      mapHeight = mapHeight + "px";
      let map = document.getElementById("mapCanvas");
      map.style.height = mapHeight;
    },
    async initializeScrollData() {
      try {
        const scrollsResponse = await axios.get(
          `/data/scrolls/${this.$route.params.scrollId}.json`
        );
        this.setScrollData(scrollsResponse.data);

        const versesResponse = await axios.get(
          `/data/verses/${this.$route.params.scrollId}.json`
        );
        this.setVerses(versesResponse.data);

        const torahGroupsResponse = await axios.get(
          `/data/groups/salonika-torah.json`
        );

        this.setGroups({
          groupType: "salonika-torah",
          groups: torahGroupsResponse.data,
        });

        const haftarahGroupsResponse = await axios.get(
          `/data/groups/salonika-haftarah.json`
        );

        this.setGroups({
          groupType: "salonika-haftarah",
          groups: haftarahGroupsResponse.data,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    isMapInitialized: function () {
      setTimeout(() => {
        this.matchHeight();

        if (!this.isMapHeightInitialized) {
          this.isMapHeightInitializedStarted = true;
          setTimeout(() => {
            this.isMapHeightInitialized = true;
          }, 500);
        }
      }, 300);
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = translations[value][0];
      this.initializeHotspots();
    },
  },
  async mounted() {
    this.matchHeight();
    this.$nextTick(function () {
      window.addEventListener("resize", this.matchHeight);
      this.matchHeight();
    });

    await this.initializeScrollData();
    await this.initializeMap();
    await this.initializeHotspots();

    this.isMapInitialized = true;

    if (this.$route.query.verse) {
      this.openVersesPanel(this.$route.query.verse);
    }
  },
};
</script>
