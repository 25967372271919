// import { clone } from "lodash";

// initial state
const state = () => ({
    isVersesPanelActive: false,
    refs: []
})

// getters
const getters = {}

// actions
const actions = {

    openVersesPanel({ commit, rootState }, verseId) {
        let verse = rootState.map.verses.find(verse => verse.id === verseId);
        commit('map/setScrollTo', true, { root: true });
        commit('map/setSelectedVerse', verse, { root: true });
        commit('setIsVersesPanelActive', true)
    },
    closeVersesPanel({ commit }) {
        commit('setIsVersesPanelActive', false);
    },
    populateRefs({ commit }, refs) {
        commit('setRefs', refs);
    },

}

// mutations
const mutations = {
    // pushProductToCart(state, { id }) {
    //   state.items.push({
    //     id,
    //     quantity: 1
    //   })
    // },

    // incrementItemQuantity(state, { id }) {
    //   const cartItem = state.items.find(item => item.id === id)
    //   cartItem.quantity++
    // },

    setIsVersesPanelActive(state, isVersesPanelActive) {
        state.isVersesPanelActive = isVersesPanelActive;
    },

    setRefs(state, refs) {
        state.refs = refs;
    },

    // setCheckoutStatus(state, status) {
    //   state.checkoutStatus = status
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}