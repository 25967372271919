<template>
  <div class="footer__navigation">
    <button class="button button--close" @click="setIsMenuActive(false)">
      <i class="icon icon--close"></i>
    </button>
    <div class="footer__navigation-wrap">
      <div class="footer__navigation-header">
        <h2 class="footer__navigation-heading">
          {{ text.navigate_this_scroll }}
        </h2>
        <div class="filter">
          <h2 class="filter__heading">{{ text.jump_to }}:</h2>
          <form action="#" method="GET" class="form">
            <div class="form__row">
              <div class="form__row-wrap">
                <div class="form__col form__col--6">
                  <div class="form__group">
                    <label for="chooseBook" class="form__label"
                      >{{ text.book }}:</label
                    >
                    <div class="form__select">
                      <i class="icon icon--arrow-down"></i>
                      <select v-model="book" id="chooseBook">
                        <option :value="null">---</option>
                        <option
                          v-for="(book, index) in bookOptions"
                          :key="index"
                          :value="book"
                        >
                          {{ book }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form__col form__col--3">
                  <div class="form__group">
                    <label for="chooseChapter" class="form__label"
                      >{{ text.chapter }}:</label
                    >
                    <div class="form__select">
                      <i class="icon icon--arrow-down"></i>
                      <select
                        name="chooseChapter"
                        v-model="chapter"
                        id="chooseChapter"
                      >
                        <option :value="null">---</option>
                        <option
                          v-for="(chapter, index) in chapterOptions"
                          :key="index"
                          :value="chapter"
                        >
                          {{ chapter }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form__col form__col--3">
                  <div class="form__group">
                    <label for="chooseVerse" class="form__label"
                      >{{ text.verse }}:</label
                    >
                    <div class="form__select">
                      <i class="icon icon--arrow-down"></i>
                      <select
                        name="chooseVerse"
                        id="chooseVerse"
                        @change="goToVerse()"
                        v-model="verse"
                      >
                        <option :value="null">---</option>
                        <option
                          v-for="(verse, index) in verseOptions"
                          :key="index"
                          :value="verse"
                        >
                          {{ verse }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__row">
              <div class="form__row-wrap">
                <div class="form__col">
                  <div class="form__group">
                    <label for="chooseGroups" class="form__label"
                      >{{ text.readings }}:</label
                    >
                    <div class="form__select">
                      <i class="icon icon--arrow-down"></i>
                      <select
                        v-model="groupStartVerseId"
                        id="chooseGroups"
                        @change="goToVerse(groupStartVerseId)"
                      >
                        <option :value="null">---</option>
                        <option
                          v-for="group in groups"
                          :key="group.startVerse"
                          :value="group.startVerse"
                        >
                          {{ group.title[lang] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ul class="menu menu--footer" id="footer-menu">
        <li
          v-for="(book, book_index) in structuredGroups"
          :key="book_index"
          :class="[
            'menu__item',
            {
              'menu__item--has-children': book.groups && book.groups.length > 0,
            },
            { 'menu__item--active': book_index === openedBook },
          ]"
        >
          <a
            v-if="book.title !== 'Blessing'"
            @click="openedBook = book_index === openedBook ? null : book_index"
            href="#"
            class="menu__link"
            :ariaLabel="book.title"
            :title="book.title"
            >{{ book.title }}</a
          >
          <ul class="submenu">
            <li
              class="submenu__item"
              v-for="(group, group_index) in book.groups"
              :key="group_index"
            >
              <a
                @click="goToVerse(group.startVerse)"
                href="#"
                :ariaLabel="group.title[this.lang]"
                :title="group.title[this.lang]"
                >{{ group.title[this.lang] }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import translations from "../../public/data/additional_translations/section-menu.json";

export default {
  name: "SectionMenu",
  data() {
    let savedLang = window.localStorage.getItem("siteLang")
      ? window.localStorage.getItem("siteLang")
      : "en";
    return {
      lang: savedLang,
      books: [],
      openedBook: null,
      groupStartVerseId: null,
      book: null,
      chapter: null,
      verse: null,
      text: [],
    };
  },
  computed: {
    ...mapState("menu", ["isMenuActive", "isCurrentLanguage"]),
    ...mapState("map", ["verses"]),
    ...mapGetters("map", ["structuredGroups", "groups"]),
    bookOptions() {
      let books = this.verses.map((verse) => verse.book[this.lang]);
      books = books.filter((book, index) => {
        return (
          book !== undefined &&
          book !== "Blessing" &&
          book !== "Haftarah" &&
          books.indexOf(book) === index
        );
      });
      return books;
    },
    chapterOptions() {
      let chapters = this.verses.map((verse) => {
        return verse.book[this.lang] === this.book &&
          verse.chapter !== undefined
          ? verse.chapter
          : null;
      });
      chapters = chapters.filter((chapter, index) => {
        return chapter !== null && chapters.indexOf(chapter) === index;
      });
      chapters.sort((a, b) => parseInt(a) - parseInt(b));
      return chapters;
    },
    verseOptions() {
      let verses = this.verses.map((verse) => {
        return verse.book[this.lang] === this.book &&
          verse.chapter === this.chapter &&
          verse.verse !== undefined
          ? verse.verse
          : null;
      });
      verses = verses.filter((verse) => verse !== null);
      verses.sort((a, b) => parseInt(a) - parseInt(b));
      return verses;
    },
  },
  beforeMount() {
    this.text = translations[this.lang][0];
  },
  watch: {
    book: function (newBook) {
      if (newBook === null) {
        this.chapter = null;
        this.verse = null;
      }
    },
    chapter: function (newChapter) {
      if (newChapter === null) {
        this.verse = null;
      }
    },
    isCurrentLanguage(value) {
      this.lang = value;
      this.text = translations[value][0];
    },
  },
  methods: {
    ...mapMutations("menu", ["setIsMenuActive"]),
    ...mapActions("verses_panel", ["openVersesPanel"]),
    goToVerse(verseId) {
      let verse = this.verses.find((verse) => {
        if (verseId) {
          return verse.id === verseId;
        } else {
          return (
            verse.book[this.lang] === this.book &&
            verse.chapter === this.chapter &&
            verse.verse === this.verse
          );
        }
      });

      if (!verse) {
        this.verse = null;
        return false;
      }
      this.setIsMenuActive(false);
      this.openVersesPanel(verse.id);
      if (verse.hotspot && verse.hotspot.length) {
        console.log("verse.hotspot[0][0]", verse.hotspot[0][0]);
        window.map.panTo(verse.hotspot[0][0]);
      }

      this.book = null;
      this.groupStartVerseId = null;
    },
  },
};
</script>