// initial state
const state = () => ({});

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setScrollData(state, scrollData) {
    for (const i in scrollData) {
      state[i] = scrollData[i]
    }
  },
  setRightLng(state, lng) {
    state.scrollRightLng = lng;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}